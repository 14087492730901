.wine-menu{
padding-left: 2rem ;
margin-left: 4rem;
}

.wine-menu-2{
    padding-left: 2rem ;
    margin-left: 4rem;
    }


  @media only screen and (min-width: 1201px) {

    .wine-menu{
        padding-left: 2rem ;
        }
  
  }
  
  @media only screen and (min-width: 993px) and (max-width: 1200px){
  
    .wine-menu{
        padding-left:4.5rem ;
       
        }
  }
  
  @media only screen and (min-width: 771px) and (max-width: 992px){
  
    .wine-menu{
        padding-left: 3rem ;
        }
  
  }
  
  @media only screen and (max-width: 770px) {
  
    .wine-menu{
        padding-left: 2.2rem ;
        }
        
  }