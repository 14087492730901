.order-wrapper{
    width:70%;
}

.order-column{

    flex-direction: row;
}

.order-column > div{
    width: 48%;
}

.product-title{
    font-size: 1.3rem;
}

.receipt{
    font-size:1.5rem;
    width:40%;
}

  @media only screen and (min-width: 1201px) {
  
    .order-column{
        flex-direction: row;
    }

    .product-title{
        font-size: 1.2rem;
    }
  
  }
  
  @media only screen and (min-width: 993px) and (max-width: 1200px){

    .order-wrapper{
        width:80%;
    }
  
    .order-column{
        flex-direction: row;
    }

    .product-title{
        font-size: 1.2rem;
    }

    .receipt{
        width:80%;
    }

  }
  
  @media only screen and (min-width: 771px) and (max-width: 992px){

    .order-wrapper{
        width:80%;
    }
  
    .order-column{
        flex-direction: column;
        align-items:center;
    }

    .order-column > div{
        width: 90%;
    }

    .product-title{
        font-size: 1rem;
    }

    .receipt{
       
        width:80%;
    }
  
  }
  
  @media only screen and (max-width: 770px) {
  
    .order-wrapper{
        width:100%;
    }
   
    .order-column{
        flex-direction: column;
        align-items:center;
        
    }

    .order-column > div{
        width: 90%;
    }

    .product-title{
        font-size: 1rem;
    }

    .receipt{
        font-size:1.51rem;
    }

    .receipt{
        width:95%;
    }
        
  }
  