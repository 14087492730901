#title{

    font-weight: 600;
    margin:auto ; 
    width:50%;
    font-size:4rem ;
    letter-spacing:0.3rem;
    text-align:center;

}

.menuTitle {
    font-weight: 400;
    margin-left:4rem ; 
    width:50%;
    font-size:3rem; 
    letter-spacing:0.3rem;
    text-align:left;
}

.menuTitle-2{
    
          font-weight: 400; 
          margin:auto; 
          width:50%;
          font-size:3rem;
          letter-spacing:0.3rem;
          text-align:center;
        }


.menu1 {
    width:50%; 
}

.menu2 {
    width:55%; 
}

.menu3 {
    width:70%; 
}

.order-section{
    margin: auto;
    background-color: black;
    height: 210vh;
    font-size: 3rem;
    letter-spacing: 0.1rem;
    text-align: center;
    backdrop-filter: blur(5px);
    z-index: 10;
}


          
    

@media only screen and (min-width: 1201px) {

    #title{

        font-weight: 600;
        margin:auto ; 
        width:50%;
        font-size:4rem ;
        letter-spacing:0.3rem;
        text-align:center;
    
    }

}

@media only screen and (min-width: 993px) and (max-width: 1200px){

    #title{

        font-weight: 600;
        margin:auto ; 
        width:50%;
        font-size:3rem ;
        letter-spacing:0.3rem;
        text-align:center;
    
    }

}

@media only screen and (min-width: 771px) and (max-width: 992px){

    #title{

        font-weight: 600;
        margin:auto ; 
        width:50%;
        font-size:3rem ;
        letter-spacing:0.3rem;
        text-align:center;
    
    }

    .menuTitle {
        font-weight: 400;
        margin-left:3rem ; 
        width:50%;
        font-size:3rem; 
        letter-spacing:0.3rem;
        text-align:left;
    }

 
        

}

@media only screen and (max-width: 770px) {


    .order-section{
        height: 400vh;
    }
    #title{

        font-weight: 600;
        margin:auto ; 
        width:100%;
        font-size:3rem ;
        letter-spacing:0.3rem;
        text-align:center;
    
    }

    .menuTitle {
        font-weight: 400;
        margin-left:2.5rem ; 
        width:50%;
        font-size:2rem; 
        letter-spacing:0.3rem;
        text-align:left;
    }


    .menuTitle-2{
    
        font-weight: 400; 
        margin:auto; 
        width:80%;
        font-size:2rem;
        letter-spacing:0.3rem;
        text-align:center;
    }

      
    .menu1{
        width:80%;
    }

    .menu2 {
        width:100%; 
    }

    .menu3 {
        width:80%; 
    }
        
}
    