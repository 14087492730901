.nav{
    font-size: 1.4rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.nav-right > div{
    padding: 0 2rem ;
}

.logo{
    height: calc( 4rem - 5px );
}

@media only screen and (min-width: 1201px) {

    .nav{
        font-size: 1.4rem;
    }
  
  }
  
  @media only screen and (min-width: 993px) and (max-width: 1200px){
  
    .nav{
        font-size: 1.3rem;
    }
  }
  
  @media only screen and (min-width: 771px) and (max-width: 992px){
  
    .nav{
        font-size: 1.1rem;
    }
  
  }
  
  @media only screen and (max-width: 770px) {

    .logo{
        height: 2.5rem;
    }
  
    .nav{
        font-size: 1rem;
     
    }

    .nav-right {
        width: 58%;
       
    }
    .nav-right > div{
        padding: 0 0 1rem 0 ;
       
    }
        
  }