@media only screen and (min-width: 770px) {

    .content{
        display: flex;
        flex-direction:row;
        gap:4rem;
    }

    .content-left{
    width:55%;
    font-size:1.3rem;
    font-weight:300;
    }   

    .content-right{
    width: 40%;
    }


}

@media only screen and (max-width: 770px) {

    .content{
        display: flex;
        flex-direction:column-reverse;
        gap:3rem;
    }


    .content-left{
        width:100%;
        font-size:1.3rem;
        font-weight:300;
        padding-bottom: 5rem;
        }   
    
    .content-right{
        width: 100%;
    }

}