

@media only screen and (min-width: 770px) {

    .content-wrapper{
        width: calc(70% + 2rem);
        height:100vh;
        margin:0 auto; 
        padding-top:10vh; 
        text-align:center;
    }
}

@media only screen and (max-width: 770px) {

    .content-wrapper{
        width:100%;
        height:100vh;
        margin:0 auto; 
        padding-top:10vh; 
        text-align:center;
    }

    
}

